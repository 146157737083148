import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import {
  CreateDocumentWithTemplateRequest,
  CreateDocumentWithTemplateResponse,
  GetDocumentsResponse
} from './useDocumentApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useDocument = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()

  const useCreateDocumentWithTemplate = () =>
    useMutation({
      mutationFn: (data: CreateDocumentWithTemplateRequest) => {
        return handleApi<
          CreateDocumentWithTemplateRequest,
          CreateDocumentWithTemplateResponse
        >(`/ecm/documents/templates`, 'POST', data)
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.CreateDocumentWithTemplate]
        })
      }
    })

  const useGetDocuments = () =>
    useMutation({
      mutationFn: async ({ documentIds }: { documentIds: string[] }) => {
        const queryParams = documentIds
          .map((id) => `documentIds=${encodeURIComponent(id)}`)
          .join('&')

        const response = await handleApi<void, GetDocumentsResponse>(
          `/ecm/documents?${queryParams}`,
          'GET'
        )
        return response
      },
      ...queryConfig
    })

  return {
    useCreateDocumentWithTemplate,
    useGetDocuments
  }
}

export default useDocument

'use client'

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'react'
import mixpanel from 'mixpanel-browser'
import { useRouter } from 'next/router'
import { v4 as uuidv4 } from 'uuid'
import useEnvelopeActions from '@/hooks/useEnvelopeActions'
import useEnvelopeStates from '@/hooks/useEnvelopeStates'
import useLocalStorage from '@/hooks/useLocalStorage'
import { useSteps } from '@/hooks/useSteps'
import useTranslation from '@/hooks/useTranslation'
import type { EnvelopeContextType, Props } from './EnvelopeV2.types'
import { StepEnum } from './EnvelopeV2.types'
import { useAuth } from '../Auth'
import { useLocale } from '../Locale'
import { useQueryParams } from '../QueryParams'
import useDocument from '@/hooks/api/ecm/useDocumentApi/useDocumentApi'
import UseEnvelopeApi from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi'
import {
  DocumentEnvelope,
  SignerEnvelopeResponse,
  SignerEnvelopeStatus
} from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { useVaultApi } from '@/hooks/api/ecm/useVaultApi'
import { CurrentVaultId } from '@/ui/atoms/SelectVault'

const store = createContext<EnvelopeContextType>({} as EnvelopeContextType)
const { Provider } = store

function EnvelopeContextV2({ children }: Props) {
  const { t, isReady } = useTranslation(
    ['envelopeV2', 'language', 'validations'],
    true
  )
  const { lang } = useLocale()
  const { userContext } = useAuth()
  const [currentVaultId, setCurrentVaultId, isLoadingCurrentVaultId] =
    useLocalStorage<CurrentVaultId>('currentVaultId', null)
  const { push } = useRouter()

  const states = useEnvelopeStates()

  const { useGetEnvelope } = UseEnvelopeApi()
  const { data: dataEnvelope, isError: isErrorEnvelope } = useGetEnvelope(
    states.currentActionEnvelope?.action === 'edit' &&
      states.currentActionEnvelope?.id
      ? states.currentActionEnvelope?.id
      : null
  )

  const stepsItems = useMemo(() => {
    const stepNames = ['documents', 'signers', 'signatureMarker', 'details']
    const baseSteps = stepNames.map((name) => ({
      name,
      label: t?.steps?.[name]?.label,
      description: t?.steps?.[name]?.description,
      isCompleted: false
    }))

    return baseSteps.map((step, index) => ({
      ...step,
      id: index
    }))
  }, [t])

  const steps = useSteps(stepsItems)

  const actions = useEnvelopeActions({
    ...states,
    dataEnvelope,
    steps
  })

  const {
    getQueryParam,
    setQueryParam,
    isReady: isReadyQueryParams
  } = useQueryParams()
  const documentsIdsQueryParam = getQueryParam<string>('documents', null)
  const stepQueryParam = getQueryParam<StepEnum | null>('step', null)
  const originQueryParam = getQueryParam<string>('origin', null)
  const { useGetDocuments } = useDocument()
  const { useGetFirstVault, useGetVault, useCreateVault } = useVaultApi()
  const { mutateAsync: mutateAsyncGetDocuments } = useGetDocuments()
  const { data: dataFirstVault, isFetched: isFetchedFirstVault } =
    useGetFirstVault(!isLoadingCurrentVaultId && !currentVaultId ? true : false)
  const { data: dataVault, isFetched: isFetchedVault } = useGetVault(
    !isLoadingCurrentVaultId && currentVaultId ? currentVaultId : null
  )
  const { mutateAsync: mutateAsyncCreateVault } = useCreateVault()

  // Função para criar um cofre inicial se não houver nenhum
  const createInitialVault = useCallback(async () => {
    const response = await mutateAsyncCreateVault({
      name: t?.defaultVaultName,
      type: 'UserAccount'
    })
    if (response?.vaultId) {
      setCurrentVaultId(response?.vaultId)
    }
  }, [mutateAsyncCreateVault, setCurrentVaultId, t?.defaultVaultName])

  // useEffect para verificar e criar o cofre inicial se necessário
  useEffect(() => {
    if (isFetchedFirstVault && dataFirstVault?.items?.length === 0) {
      createInitialVault()
    }
  }, [createInitialVault, dataFirstVault?.items?.length, isFetchedFirstVault])

  const handleInitialSetup = useCallback(() => {
    if (states.finishedInitialSetup || !isReady) return
    if (
      states.currentActionEnvelope.action === 'create' &&
      !states.watchedValues?.vault &&
      ((isFetchedFirstVault && !!dataFirstVault?.items?.length) ||
        isFetchedVault)
    ) {
      if (
        userContext?.userAccountInformation?.name &&
        states.signers?.length === 0
      ) {
        states.setSigners([
          {
            index: 0,
            id: 'fake-id-' + uuidv4(),
            name: userContext?.userAccountInformation?.name,
            email: userContext?.userAccountInformation?.email,
            role: 'Sign',
            title: null,
            shouldEnforceEmailValidation: false,
            shouldEnforcePasscodeValidation: false,
            shouldEnforceWhatsAppValidation: false,
            passcode: null,
            passcodeHint: null,
            shouldEnforceSmsValidation: false,
            phoneNumber: null,
            phoneIdd: null,
            documentType: null,
            documentValue: null,
            signMarks: [],
            status: SignerEnvelopeStatus.None,
            actions: []
          }
        ])
      }

      states.form.reset((prev) => ({
        ...prev,
        vault: isFetchedFirstVault
          ? {
              label: dataFirstVault?.items?.[0]?.name || '',
              value: dataFirstVault?.items?.[0]?.vaultId || ''
            }
          : {
              label: dataVault?.name || '',
              value: dataVault?.id || ''
            },
        name: t?.defaultName,
        message: t?.defaultMessage,
        reviewReminder: true,
        markupOrientation: 'Bottom',
        language: lang
      }))

      states.setFinishedInitialSetup(true)
    }
    if (
      states.currentActionEnvelope.action === 'edit' &&
      states.currentActionEnvelope.id &&
      dataEnvelope &&
      dataEnvelope?.vaultId
    ) {
      const initialValues = actions.formatInitialValues(dataEnvelope)
      states.form.reset((prev: any) => ({
        ...prev,
        ...initialValues
      }))

      const initialSigners = actions.formatInitialSigners(dataEnvelope.signers)

      states.setSigners(initialSigners as SignerEnvelopeResponse[])

      const initialDocuments = dataEnvelope?.documents?.sort(
        (a, b) => a.index - b.index
      )
      states.setDocuments(initialDocuments)
      const envelopePayload = actions.getPayloadEnvelope(
        initialValues,
        initialDocuments,
        initialSigners
      )
      states.setLastUpdatedJson(JSON.stringify(envelopePayload))
      states.setFinishedInitialSetup(true)
    }
  }, [
    actions,
    dataEnvelope,
    dataFirstVault?.items,
    dataVault?.id,
    dataVault?.name,
    isFetchedFirstVault,
    isFetchedVault,
    isReady,
    lang,
    states,
    t?.defaultMessage,
    t?.defaultName,
    userContext?.userAccountInformation?.email,
    userContext?.userAccountInformation?.name
  ])

  // useEffect(() => {
  //   states.form.trigger()
  // }, [states.form])

  useEffect(() => {
    handleInitialSetup()
  }, [handleInitialSetup])

  useEffect(() => {
    if (isErrorEnvelope && states.currentActionEnvelope.action === 'edit') {
      push('/')
    }
    if (!isReadyQueryParams) return

    if (documentsIdsQueryParam) {
      mutateAsyncGetDocuments({
        documentIds: documentsIdsQueryParam?.split(',')
      }).then((response) => {
        const newDocuments = response?.items?.map((document, index) => ({
          id: document.id,
          index,
          name: document.name,
          privateDescription: document.privateDescription,
          publicDescription: document.publicDescription,
          pageSizes: document.pageSizes
        }))
        states.setDocuments(newDocuments as DocumentEnvelope[])
      })
      setQueryParam('documents', null)
    }
    // Tracking
    if (!states.sendedTracking && mixpanel?.has_init) {
      const trackData = originQueryParam
        ? { origin: originQueryParam }
        : undefined
      mixpanel.track('Create envelope', trackData)
      states.setSendedTracking(true)

      if (originQueryParam) {
        setQueryParam('origin', null)
      }
    }
    if (
      isReadyQueryParams &&
      stepQueryParam &&
      [
        StepEnum.DOCUMENTS,
        StepEnum.SIGNERS,
        StepEnum.SIGNATURE_MARKER,
        StepEnum.DETAILS
      ].includes(stepQueryParam)
    ) {
      steps.goToStep(stepQueryParam)
      setQueryParam('step', null)
    }
  }, [
    documentsIdsQueryParam,
    isErrorEnvelope,
    isReadyQueryParams,
    mutateAsyncGetDocuments,
    originQueryParam,
    push,
    setQueryParam,
    states,
    stepQueryParam,
    steps
  ])

  const hasReviewed = useMemo(() => {
    const allowedStatus = [
      SignerEnvelopeStatus.Approved,
      SignerEnvelopeStatus.Rejected
    ]
    return (states.signers as SignerEnvelopeResponse[])?.some(
      (signer) => signer.status && allowedStatus.includes(signer.status)
    )
  }, [states.signers])

  const valuesSelectDocuments = useMemo(
    () =>
      states.documents?.map((file) => ({
        id: file?.id,
        value: file?.name,
        label: file?.name?.replace('.pdf', '')
      })),
    [states.documents]
  )

  const showSkeleton = useMemo(
    () => !isReady || !states.finishedInitialSetup,
    [isReady, states.finishedInitialSetup]
  )

  return (
    <Provider
      value={{
        currentVaultId,
        setCurrentVaultId,
        valuesSelectDocuments,
        hasReviewed,
        dataEnvelope,
        steps,
        showSkeleton,
        ...actions,
        ...states
      }}
    >
      {children}
    </Provider>
  )
}

const useEnvelopeV2 = () => useContext(store)

export { EnvelopeContextV2, useEnvelopeV2 }

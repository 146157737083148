import { Dispatch, SetStateAction } from 'react'
import { Lang, MarkupOrientationType } from '@/types/global'
import { EnvelopeDataResponse } from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { UseEnvelopeActionsResponse } from '@/hooks/useEnvelopeActions/useEnvelopeActions.types'
import { UseEnvelopeStatesResponse } from '@/hooks/useEnvelopeStates/useEnvelopeStates.types'
import type { UseStepsReturnType } from '@/hooks/useSteps/useSteps'
import { type CurrentVaultId } from '@/ui/atoms/SelectVault'

export type Props = {
  children?: React.ReactNode
}

export type LoadingDocumentType = {
  documentId: string | null
  loading: boolean
}

export type CurrentValuesEnvelope = {
  vault: {
    value: string
    label: string
  } | null
  name: string
  message: string
  reviewReminder: boolean
  buttonShowAdvancedSettings: boolean
  toBePublished: boolean
  toBePublishedAtUtc: string | null
  hasExpiration: boolean
  expirationReminder: boolean
  expiresAtUtc: string | null
  language: Lang | null
  privateDescription: string
  publicDescription: string
  signInOrder: boolean
  markupOrientation: MarkupOrientationType
}

export enum StepEnum {
  DOCUMENTS = 'documents',
  DETAILS = 'details',
  SIGNERS = 'signers',
  SIGNATURE_MARKER = 'signatureMarker'
}

export type EnvelopeContextType = UseEnvelopeActionsResponse &
  UseEnvelopeStatesResponse & {
    currentVaultId: CurrentVaultId
    setCurrentVaultId: Dispatch<SetStateAction<CurrentVaultId>>
    valuesSelectDocuments: {
      id: string
      value: string
      label: string
    }[]
    hasReviewed: boolean
    dataEnvelope: EnvelopeDataResponse | null | undefined
    steps: UseStepsReturnType
    showSkeleton: boolean
  }

export type CurrentActionEnvelopeType = {
  id: string | null
  action: 'create' | 'edit' | null
}

export enum EnumConfirmManager {
  NO_ENVELOPES = 'noEnvelopes',
  NO_SIGNER_MFA_CREDITS = 'noSignerMfaCredits',
  NO_API_ENVELOPES = 'noApiEnvelopes',
  PUBLICATION_DATE_BEFORE_NOW = 'publicationDateBeforeNow',
  EXPIRATION_DATE_BEFORE_NOW = 'expirationDateBeforeNow',
  REMOVE_SCHEDULE_FOR_SAVE = 'removeScheduleForSave',
  REMOVE_SCHEDULE_FOR_PUBLISH = 'removeScheduleForPublish',
  NO_SIGNATURE_MARKS = 'noSignatureMarks'
}

export enum EnumHelpManager {
  DOCUMENT_IS_REQUIRED_FOR_PUBLISH = 'documentIsRequiredForPublish',
  SIGNER_IS_REQUIRED_FOR_PUBLISH = 'signerIsRequiredForPublish',
  TOO_MANY_REQUESTS_FOR_SAVE = 'tooManyRequestsForSave',
  TOO_MANY_REQUESTS_FOR_PUBLISH = 'tooManyRequestsForPublish',
  CREATE_ENVELOPE_FOR_SAVE = 'createEnvelopeForSave',
  CREATE_ENVELOPE_FOR_PUBLISH = 'createEnvelopeForPublish',
  VAULT_IS_REQUIRED_FOR_SAVE = 'vaultIsRequiredForSave',
  VAULT_IS_REQUIRED_FOR_PUBLISH = 'vaultIsRequiredForPublish'
}

export type ToggleValuesType = {
  signatureInOrder: boolean
  positionSignatureMarks: boolean
}

import { useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { getSignMarks } from '@/utils/envelope'
import { useLocale } from '@/providers/Locale'
import {
  ClickedForSignerType,
  CurrentActionEnvelopeType,
  CurrentSignerActionType,
  CurrentSignerContact,
  CurrentValuesEnvelope,
  IsLoadingStepType,
  LoadingDocumentType,
  UseEnvelopeStatesResponse
} from './useEnvelopeStates.types'
import {
  DocumentEnvelope,
  SignerEnvelopeResponse
} from '../api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import useTranslation from '../useTranslation'

const useEnvelopeStates = (): UseEnvelopeStatesResponse => {
  const { t } = useTranslation(['envelopeV2', 'language', 'validations'], true)
  const { lang } = useLocale()

  const [currentActionEnvelope, setCurrentActionEnvelope] =
    useState<CurrentActionEnvelopeType>({ id: null, action: null })
  const [document, setDocument] = useState<DocumentEnvelope | null>(null)
  const [documents, setDocuments] = useState<DocumentEnvelope[]>([])
  const [loadingDocument, setLoadingDocument] = useState<LoadingDocumentType>({
    documentId: null,
    loading: false
  })
  const [finishedInitialSetup, setFinishedInitialSetup] = useState(false)
  const [valuesDocumentTemplateFiller, setValuesDocumentTemplateFiller] =
    useState<Record<string, string>>({})
  const [sendedTracking, setSendedTracking] = useState(false)
  const [signers, setSigners] = useState<SignerEnvelopeResponse[]>([])
  const [clickedForSigner, setClickedForSigner] =
    useState<ClickedForSignerType | null>(null)
  const [currentSignerAction, setCurrentSignerAction] =
    useState<CurrentSignerActionType>({
      id: null,
      isOpen: false,
      action: null
    })
  const [currentSignerContact, setCurrentSignerContact] =
    useState<CurrentSignerContact | null>(null)
  const [isLoadingStep, setIsLoadingStep] = useState<IsLoadingStepType>({
    isLoading: false,
    step: null
  })
  const [lastUpdatedJson, setLastUpdatedJson] = useState<string | null>(null)

  const formSchema = z.object({
    vault: z.object({
      label: z.string().min(1, t?.requiredField),
      value: z.string().min(1, t?.requiredField)
    }),
    name: z
      .string()
      .min(1, t?.requiredField)
      .max(200, t?.errorMaxLength?.(200)),
    message: z.string().max(4000, t?.errorMaxLength?.(4000)),
    privateDescription: z
      .string()
      .max(4000, t?.errorMaxLength?.(4000))
      .nullable(),
    publicDescription: z
      .string()
      .max(4000, t?.errorMaxLength?.(4000))
      .nullable(),
    reviewReminder: z.boolean(),
    toBePublished: z.boolean().optional().nullable(),
    toBePublishedAtUtc: z.date().optional().nullable(),
    hasExpiration: z.boolean().optional().nullable(),
    expirationReminder: z.boolean(),
    expiresAtUtc: z.date().optional().nullable(),
    language: z.string().optional().nullable()
  })

  const form = useForm<CurrentValuesEnvelope>({
    defaultValues: {
      vault: null,
      name: '',
      message: '',
      reviewReminder: true,
      buttonShowAdvancedSettings: false,
      toBePublished: false,
      toBePublishedAtUtc: null,
      hasExpiration: false,
      expirationReminder: false,
      expiresAtUtc: null,
      language: lang,
      privateDescription: '',
      publicDescription: '',
      signInOrder: true,
      markupOrientation: 'Bottom'
    },
    resolver: zodResolver(formSchema),
    mode: 'all'
  })
  const watchedValues = useWatch<CurrentValuesEnvelope>({
    control: form.control,
    defaultValue: form.getValues()
  })

  const noSigners = useMemo(() => {
    if (!signers) return true
    return (
      signers?.length === 0 ||
      ((signers?.length || 0) > 0 && signers?.every((e) => !e.email))
    )
  }, [signers])

  const marks = useMemo(() => {
    if (!document?.id) return []
    return getSignMarks(signers, document?.id as string)
  }, [signers, document?.id])

  return {
    currentActionEnvelope,
    setCurrentActionEnvelope,
    document,
    setDocument,
    documents,
    setDocuments,
    loadingDocument,
    setLoadingDocument,
    finishedInitialSetup,
    setFinishedInitialSetup,
    valuesDocumentTemplateFiller,
    setValuesDocumentTemplateFiller,
    sendedTracking,
    setSendedTracking,
    signers,
    setSigners,
    clickedForSigner,
    setClickedForSigner,
    currentSignerAction,
    setCurrentSignerAction,
    currentSignerContact,
    setCurrentSignerContact,
    form,
    watchedValues,
    noSigners,
    marks,
    isLoadingStep,
    setIsLoadingStep,
    lastUpdatedJson,
    setLastUpdatedJson
  }
}

export default useEnvelopeStates

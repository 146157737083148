import { useCallback, useState } from 'react'
import { compare } from 'fast-json-patch'
import { useRouter } from 'next/router'
import { createMark, createOrUpdateMark } from '@/utils/index'
import { useLocale } from '@/providers/Locale'
import {
  HandleSaveProps,
  ModalManagerType,
  SelectedSignerMarkType,
  UseEnvelopeActionsProps,
  UseEnvelopeActionsResponse
} from './useEnvelopeActions.types'
import { useEnvelopeApi } from '../api/ecm/useEnvelopeApi'
import {
  DocumentEnvelope,
  DocumentEnvelopeBase,
  EnvelopeDataResponse,
  SignerEnvelopeResponse,
  SignMarkEnvelopeType,
  UpdateEnvelopeDocumentRequest,
  UpdateEnvelopeRequest,
  UpdateEnvelopeSignerRequest
} from '../api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import useTranslation from '../useTranslation'
import {
  CurrentValuesEnvelope,
  EnumConfirmManager,
  StepEnum
} from '@/providers/EnvelopeV2/EnvelopeV2.types'
import { showToast } from '@/ui/atoms/Toast'
import { SignerEnvelopeInitial } from '@/ui/molecules/ModalActionSignerV2'

const useEnvelopeActions = ({
  documents,
  signers,
  currentActionEnvelope,
  form,
  setSigners,
  setDocuments,
  clickedForSigner,
  setClickedForSigner,
  noSigners,
  setCurrentActionEnvelope,
  setIsLoadingStep,
  steps: { goToStep },
  lastUpdatedJson,
  setLastUpdatedJson
}: UseEnvelopeActionsProps): UseEnvelopeActionsResponse => {
  const { t } = useTranslation('envelopeV2')
  const { lang } = useLocale()
  const { push } = useRouter()
  const [modalManager, setModalManager] = useState<ModalManagerType>({
    open: false,
    type: null
  })

  const { useCreateEnvelope, usePublishEnvelope, useUpdateEnvelope } =
    useEnvelopeApi()
  const {
    mutateAsync: mutateAsyncCreateEnvelope,
    isLoading: isLoadingCreateEnvelope
  } = useCreateEnvelope()
  const {
    mutateAsync: mutateAsyncPublishEnvelope,
    isLoading: isLoadingPublishEnvelope
  } = usePublishEnvelope()
  const {
    mutateAsync: mutateAsyncUpdateEnvelope,
    isLoading: isLoadingSaveEnvelope
  } = useUpdateEnvelope()

  const formatSignersForSave = useCallback(
    (
      signers: UpdateEnvelopeSignerRequest[],
      documents: UpdateEnvelopeDocumentRequest[] | null
    ): UpdateEnvelopeSignerRequest[] => {
      return signers?.map((signer): UpdateEnvelopeSignerRequest => {
        const signerMarks = signer?.signMarks?.length
          ? signer?.signMarks
              ?.map((signMark: SignMarkEnvelopeType) => {
                // Verificar se o documento existe, se não existir, não adicionar o signMark
                const documentExists = documents?.find(
                  (document) => document.id === signMark.documentId
                )

                if (!documentExists) {
                  return
                }

                const { id, ...rest } = signMark
                const extraPropsSignMark = !id?.includes('fake-id-')
                  ? { id }
                  : {}

                return {
                  ...extraPropsSignMark,
                  ...rest
                }
              })
              ?.filter(
                (mark): mark is SignMarkEnvelopeType => mark !== undefined
              )
          : []

        const extraPropsSigner = !signer?.id?.includes('fake-id-')
          ? { id: signer?.id }
          : {}

        return {
          ...extraPropsSigner,
          name: signer.name,
          email: signer.email,
          role: signer.role,
          title: signer.title,
          shouldEnforceEmailValidation: signer.shouldEnforceEmailValidation,
          shouldEnforcePasscodeValidation:
            signer.shouldEnforcePasscodeValidation,
          shouldEnforceWhatsAppValidation:
            signer.shouldEnforceWhatsAppValidation,
          passcode: signer.passcode || null,
          passcodeHint: signer.passcodeHint || null,
          documentType: signer.documentType,
          documentValue: signer.documentValue,
          shouldEnforceSmsValidation: signer.shouldEnforceSmsValidation,
          phoneIdd: signer?.phoneIdd || null,
          phoneNumber: signer?.phoneNumber || null,
          signMarks: signerMarks
        }
      })
    },
    []
  )

  const formatDocumentsForSave = useCallback(
    (
      documents: DocumentEnvelopeBase[] | UpdateEnvelopeDocumentRequest[] | null
    ): DocumentEnvelopeBase[] => {
      return (
        documents?.map((document) => ({
          id: document.id,
          name: document.name,
          privateDescription: document.privateDescription,
          publicDescription: document.publicDescription,
          language: document.language,
          markupOrientation: document.markupOrientation
        })) || []
      )
    },
    []
  )

  const formatInitialValues = useCallback(
    (values: EnvelopeDataResponse): CurrentValuesEnvelope => {
      const hasToBePublishedAtUtc = !!values?.toBePublishedAtUtc
      const hasExpiration = !!values?.expiresAtUtc
      const buttonShowAdvancedSettings = hasToBePublishedAtUtc || hasExpiration

      const formattedValues: CurrentValuesEnvelope = {
        vault: {
          label: values?.vaultName || '',
          value: values?.vaultId || ''
        },
        name: values?.name || '',
        message: values?.message || '',
        privateDescription: values?.privateDescription || '',
        publicDescription: values?.publicDescription || '',
        reviewReminder: values?.reviewReminder || false,
        buttonShowAdvancedSettings: buttonShowAdvancedSettings,
        toBePublished: !!values?.toBePublishedAtUtc,
        toBePublishedAtUtc: values?.toBePublishedAtUtc || null,
        hasExpiration: hasExpiration,
        expirationReminder: values?.expirationReminder || false,
        expiresAtUtc: values?.expiresAtUtc || null,
        language: values?.language || null,
        signInOrder: values?.signInOrder || true,
        markupOrientation: values?.markupOrientation || 'Bottom'
      }

      return formattedValues
    },
    []
  )

  const formatInitialSigners = useCallback(
    (signers: SignerEnvelopeResponse[]): SignerEnvelopeResponse[] => {
      return signers
        ?.map((signer) => ({
          ...signer,
          role: t?.optionsRoles?.find(
            (role: { value: string }) => role.value === signer.role
          )?.value,
          shouldEnforceEmailValidation:
            signer.shouldEnforceEmailValidation || false,
          shouldEnforcePasscodeValidation:
            signer.shouldEnforcePasscodeValidation || false,
          shouldEnforceSmsValidation:
            signer.shouldEnforceSmsValidation || false,
          shouldEnforceWhatsAppValidation:
            signer.shouldEnforceWhatsAppValidation || false,
          documentType: signer.documentType || null,
          documentValue: signer.documentValue || null,
          passcode: signer.passcode || null,
          passcodeHint: signer.passcodeHint || null,
          phoneIdd: signer.phoneIdd || null,
          phoneNumber: signer.phoneNumber || null,
          signMarks: signer.signMarks
        }))
        .sort((a, b) => a.index - b.index)
    },
    [t?.optionsRoles]
  )

  const getPayloadEnvelope = useCallback(
    (
      values: CurrentValuesEnvelope,
      documents: DocumentEnvelopeBase[] | [],
      signers: UpdateEnvelopeSignerRequest[] | []
    ): UpdateEnvelopeRequest => ({
      vaultId:
        typeof values?.vault === 'object'
          ? values.vault?.value || null
          : values?.vault || null,
      name: values.name,
      privateDescription: values.privateDescription,
      publicDescription: values.publicDescription,
      message: values.message,
      reviewReminder: values.reviewReminder,
      expiresAtUtc: values.expiresAtUtc,
      expirationReminder: values.expirationReminder,
      toBePublishedAtUtc: values.toBePublishedAtUtc,
      language: values.language || lang,
      markupOrientation: values.markupOrientation,
      signInOrder: values.signInOrder,
      documents: formatDocumentsForSave(documents),
      signers: formatSignersForSave(signers, documents)
    }),
    [formatDocumentsForSave, formatSignersForSave, lang]
  )

  // Função para criar envelope
  const handleCreateEnvelope = useCallback(
    async (formValues?: CurrentValuesEnvelope) => {
      const valueForm = {
        ...form.getValues(),
        ...(formValues || {})
      } as CurrentValuesEnvelope

      const envelopePayload = getPayloadEnvelope(valueForm, documents, signers)

      try {
        const response = await mutateAsyncCreateEnvelope(envelopePayload)
        setLastUpdatedJson(JSON.stringify(envelopePayload))

        return response?.envelopeId
      } catch (error: any) {
        console.log('error', error)
        showToast.error(t?.toasts?.errorCreateEnvelope)
      } finally {
        setCurrentActionEnvelope({
          action: 'edit',
          id: null
        })
      }
    },
    [
      signers,
      documents,
      form,
      getPayloadEnvelope,
      mutateAsyncCreateEnvelope,
      setLastUpdatedJson,
      t?.toasts?.errorCreateEnvelope,
      setCurrentActionEnvelope
    ]
  )

  // Função para salvar envelope
  const handleSaveEnvelope = useCallback(
    async (props?: HandleSaveProps) => {
      const { values: formValues, removeSchedule = false } = props || {}

      if (!currentActionEnvelope.id) {
        return null
      }

      const valueForm = {
        ...form.getValues(),
        ...(formValues || {})
      }

      const toBePublishedAtUtcValue =
        valueForm.toBePublished && valueForm.toBePublishedAtUtc
          ? valueForm.toBePublishedAtUtc
          : null
      const expiresAtUtcValue =
        valueForm.hasExpiration && valueForm.expiresAtUtc
          ? valueForm.expiresAtUtc
          : null

      const valuesForSave = {
        ...valueForm,
        name: valueForm.name || t?.defaultName,
        toBePublished: removeSchedule ? false : valueForm.toBePublished,
        toBePublishedAtUtc: removeSchedule ? null : toBePublishedAtUtcValue,
        expiresAtUtc: expiresAtUtcValue
      } as CurrentValuesEnvelope

      if (
        valuesForSave?.toBePublishedAtUtc &&
        new Date(valuesForSave?.toBePublishedAtUtc) < new Date()
      ) {
        setModalManager({
          open: true,
          type: EnumConfirmManager.PUBLICATION_DATE_BEFORE_NOW
        })
        return null
      }

      if (
        valuesForSave?.expiresAtUtc &&
        new Date(valuesForSave?.expiresAtUtc) < new Date()
      ) {
        setModalManager({
          open: true,
          type: EnumConfirmManager.EXPIRATION_DATE_BEFORE_NOW
        })
        return null
      }

      const formattedSigners =
        signers?.length && signers.length > 0
          ? formatSignersForSave(signers, documents)
          : []
      const formattedDocuments =
        documents?.length && documents.length > 0
          ? formatDocumentsForSave(documents)
          : []
      // Pegando os valores do envelope atual para comparar com o último request
      const envelopePayload = getPayloadEnvelope(
        valuesForSave,
        formattedDocuments,
        formattedSigners as SignerEnvelopeResponse[]
      )

      const lastUpdatedJs = JSON.parse(lastUpdatedJson || '{}')

      const compareData = compare(lastUpdatedJs, envelopePayload)
      const hasChanges = compareData.length > 0

      if (!hasChanges) {
        return currentActionEnvelope.id
      }

      try {
        const response = await mutateAsyncUpdateEnvelope({
          id: currentActionEnvelope.id,
          ...envelopePayload
        })
        setLastUpdatedJson(JSON.stringify(envelopePayload))

        if (response?.id) {
          const initialSigners = formatInitialSigners(response.signers)

          setSigners(initialSigners as SignerEnvelopeResponse[])
          setDocuments(response.documents)
          setCurrentActionEnvelope({
            action: 'edit',
            id: response.id
          })
        }

        return response?.id
      } catch (error) {
        showToast.error(t?.toasts?.errorUpdateEnvelope)
        return null
      }
    },
    [
      currentActionEnvelope.id,
      documents,
      form,
      formatDocumentsForSave,
      formatInitialSigners,
      formatSignersForSave,
      getPayloadEnvelope,
      lastUpdatedJson,
      mutateAsyncUpdateEnvelope,
      setCurrentActionEnvelope,
      setDocuments,
      setLastUpdatedJson,
      setSigners,
      signers,
      t?.defaultName,
      t?.toasts?.errorUpdateEnvelope
    ]
  )

  // Função para publicar envelope
  const handlePublishEnvelope = useCallback(
    async (id: string) => {
      try {
        return await mutateAsyncPublishEnvelope({ id })
      } catch (error: any) {
        if (
          error.response.status === 402 &&
          error.response.statusText === 'Payment Required' &&
          error.response.data
        ) {
          const mappingErrors = {
            Envelope: EnumConfirmManager.NO_ENVELOPES,
            ApiEnvelope: EnumConfirmManager.NO_API_ENVELOPES,
            SignerMfaCredits: EnumConfirmManager.NO_SIGNER_MFA_CREDITS
          }
          const typeError =
            mappingErrors[
              error.response.data?.shouldBuy as keyof typeof mappingErrors
            ]
          if (typeError) {
            setModalManager({
              open: true,
              type: typeError
            })
          }

          return
        }
        showToast.error(t?.toasts?.errorPublishEnvelope)

        return
      }
    },
    [mutateAsyncPublishEnvelope, t?.toasts?.errorPublishEnvelope]
  )

  // Função goToStep
  const goToStepAction = useCallback(
    async (name) => {
      setIsLoadingStep({ isLoading: true, step: name as StepEnum })

      goToStep(name)

      if (currentActionEnvelope.action === 'create') {
        const envelopeId = await handleCreateEnvelope()
        if (envelopeId) {
          push(`/envelope/${envelopeId}/edit`, undefined, {
            shallow: true
          })
        }
      }
      if (
        currentActionEnvelope.action === 'edit' &&
        !!currentActionEnvelope.id
      ) {
        const envelopeId = await handleSaveEnvelope()
        if (envelopeId) {
          push(`/envelope/${envelopeId}/edit`, undefined, {
            shallow: true
          })
        }
      }
      setIsLoadingStep({ isLoading: false, step: null })
    },
    [
      currentActionEnvelope.action,
      currentActionEnvelope.id,
      goToStep,
      handleCreateEnvelope,
      handleSaveEnvelope,
      push,
      setIsLoadingStep
    ]
  )

  /**
   * Signers
   */

  // Função para deletar signer
  const handleDeleteSigner = useCallback(
    (id: string) => {
      setSigners((prev) => {
        if (!prev) return prev
        return prev.filter((signer) => signer.id !== id)
      })
    },
    [setSigners]
  )

  // Função para adicionar marcas de assinatura
  const handleAddMark = useCallback(
    (signerSelected: SelectedSignerMarkType, document: DocumentEnvelope) => {
      const e = clickedForSigner?.e
      const page = clickedForSigner?.pageItem?.page
      const scaleFactor = clickedForSigner?.scaleFactor

      if (!signerSelected || noSigners || !e || !page || !scaleFactor) return

      e.stopPropagation()
      e.preventDefault()

      const signatureMark = createMark(
        e,
        page,
        signerSelected?.id,
        signerSelected?.type || 'Signature',
        scaleFactor
      )

      const newMark = createOrUpdateMark(document, signatureMark)

      setSigners((prevState: any) => {
        if (!prevState) return
        return prevState.map((signer: SignerEnvelopeResponse) => {
          if (signer.id === signerSelected?.id) {
            return {
              ...signer,
              signMarks: [...(signer?.signMarks || []), newMark]
            }
          }
          return signer
        })
      })

      setClickedForSigner(null)
    },
    [
      clickedForSigner?.e,
      clickedForSigner?.pageItem?.page,
      clickedForSigner?.scaleFactor,
      noSigners,
      setClickedForSigner,
      setSigners
    ]
  )

  return {
    isLoading: {
      isLoadingCreateEnvelope,
      isLoadingSaveEnvelope,
      isLoadingPublishEnvelope
    },
    handleCreateEnvelope,
    handleSaveEnvelope,
    handlePublishEnvelope,
    formatInitialValues,
    formatInitialSigners,
    modalManager,
    setModalManager,
    goToStepAction,
    handleDeleteSigner,
    handleAddMark,
    getPayloadEnvelope
  }
}

export default useEnvelopeActions

import { MarkupOrientationType, OwnerType } from '@/types/global'
import { Lang } from '@/types/global'
import { EnvelopeType } from '../useVaultApi/useVaultApi.types'
import { Pagination } from '@/hooks/useApi/useApi.types'

// Base Types and Enums
export enum EnvelopeStatus {
  Draft = 'Draft', // Rascunho
  PublishScheduled = 'PublishScheduled', // Publicação agendada
  Published = 'Published', // Publicado
  Hold = 'Hold', // Em espera
  Cancelled = 'Cancelled', // Cancelado
  CancelledBySignerMfaError = 'CancelledBySignerMfaError', // Cancelado por erro de MFA do signatário
  Rejected = 'Rejected', // Rejeitado
  Signed = 'Signed', // Assinado
  Expired = 'Expired' // Expirado
}

export enum SignerEnvelopeStatus {
  None = 'None', // Nenhum
  ReadyToReview = 'ReadyToReview', // Pronto para revisão
  Queued = 'Queued', // Em fila
  Approved = 'Approved', // Aprovado
  Rejected = 'Rejected', // Rejeitado
  CancelledByMfaError = 'CancelledByMfaError' // Cancelado por erro de MFA
}

export enum ActionType {
  View = 'View',
  Approval = 'Approval'
}

// type RoleEnvelope = 'Sign' | 'Approve'
export enum SignerRoleEnum {
  Sign = 'Sign',
  Approve = 'Approve',
  Recognize = 'Recognize',
  Testify = 'Testify',
  ConfirmReceiving = 'ConfirmReceiving',
  EndorseInBlack = 'EndorseInBlack',
  EndorseInWhite = 'EndorseInWhite',
  Confirm = 'Confirm'
}

export enum SignerDocumentEnum {
  GenericIdentification = 'GenericIdentification',
  BrazilianCpf = 'BrazilianCpf'
}

export type SignMarkEnvelopeBaseType = {
  // id: string
  type: MarkTypeEnvelope | null
  documentId: string
  page: number
  x: number
  y: number
  rotation: number
}

export type SignMarkEnvelopeType = SignMarkEnvelopeBaseType & {
  id: string
}

export type SignerEnvelopeBase = {
  name: string
  email: string | null
  role: keyof typeof SignerRoleEnum | null
  title: string | null
  shouldEnforceEmailValidation: boolean
  shouldEnforcePasscodeValidation: boolean
  shouldEnforceWhatsAppValidation: boolean
  shouldEnforceSmsValidation: boolean
  phoneIdd: number | null
  phoneNumber: string | null
  documentType: keyof typeof SignerDocumentEnum | null
  documentValue: string | null
  passcode: string | null
  passcodeHint: string | null
  signMarks: SignMarkEnvelopeBaseType[] | []
}

export type DocumentEnvelopeBase = {
  id: string
  name: string
  privateDescription: string | null
  publicDescription: string | null
  language: Lang
  markupOrientation: MarkupOrientationType
}

export type DocumentEnvelope = DocumentEnvelopeBase & {
  id: string
  index: number
  origin: string | null
  pageSizes: PageSizeEnvelopeType[]
}

export type EnvelopeBase = {
  vaultId: string | null
  name: string
  privateDescription: string | null
  publicDescription: string | null
  message: string
  reviewReminder: boolean
  expiresAtUtc: string | null
  expirationReminder: boolean
  toBePublishedAtUtc: string | null
  language: Lang
  markupOrientation: MarkupOrientationType
  signInOrder: boolean
  signers: SignerEnvelopeBase[]
  documents: DocumentEnvelopeBase[]
}

export type MarkTypeEnvelope = 'Signature' | 'Rubric' | 'Free-Signature'

export type PageSizeEnvelopeType = {
  page: number
  width: number
  height: number
}

export type SignerEnvelopeAction = {
  id: string
  createdAtUtc: string
  type: keyof typeof ActionType
  ip: string
  userAgent: string
  location: string
}

// Request Types

export type CreateEnvelopeSignerRequest = Omit<
  SignerEnvelopeBase,
  'signMarks'
> & {
  signMarks: SignMarkEnvelopeBaseType[] | []
}

export type UpdateEnvelopeSignerRequest = Omit<
  SignerEnvelopeBase,
  'signMarks'
> & {
  id?: string
  signMarks: SignMarkEnvelopeType[] | []
}

export type CreateEnvelopeDocumentRequest = DocumentEnvelopeBase

export type UpdateEnvelopeDocumentRequest = DocumentEnvelopeBase

export type DocumentEnvelopeResponse = DocumentEnvelopeBase & {
  id: string
  index: number
  origin: string | null
  pageSizes: PageSizeEnvelopeType[]
}

export type SignerEnvelopeResponse = Omit<SignerEnvelopeBase, 'signMarks'> & {
  id: string
  index: number
  status: SignerEnvelopeStatus
  actions: SignerEnvelopeAction[] | []
  signMarks: SignMarkEnvelopeType[]
}

export type EnvelopeDataResponse = Omit<
  EnvelopeBase,
  'signers' | 'documents'
> & {
  id: string
  createdAtUtc: string
  createdById: string
  createdByName: string
  createdByAvatar: string | null
  updatedAtUtc: string
  lastUpdateById: string
  updatedByName: string
  updatedByAvatar: string | null
  userAccountOwnerId: string
  userAccountOwnerName: string
  userAccountOwnerAvatar: string | null
  vaultName: string | null
  status: keyof typeof EnvelopeStatus
  certifiedAtUtc: string | null
  hasActionsBeingProcessed: boolean
  hasScheduledPublish: boolean
  isSandbox: boolean
  isRemoved: boolean
  signers: SignerEnvelopeResponse[]
  documents: DocumentEnvelopeResponse[]
}

// Create Envelope Types
export type CreateEnvelopeRequest = EnvelopeBase

export type CreateEnvelopeResponse = {
  envelopeId: string
}

// Update Envelope Types
export type UpdateEnvelopeRequest = Omit<
  EnvelopeBase,
  'signers' | 'documents'
> & {
  signers: UpdateEnvelopeSignerRequest[]
  documents: UpdateEnvelopeDocumentRequest[]
}

export type UpdateEnvelopeRequestWithId = UpdateEnvelopeRequest & {
  id: string
}

export type EnvelopeUpdateResponse = EnvelopeDataResponse

// Rename Envelope Types
export type RenameEnvelopesRequest = {
  name: string
}

export type RenameEnvelopesRequestWithId = RenameEnvelopesRequest & {
  id: string
}

// Publish Envelope Types
export type PublishEnvelopeRequest = {
  id: string
}

// Get Envelope Types
export type EnvelopeDataRequest = {
  recaptchaToken: string | null
}

export type EnvelopeDataRequestWithId = EnvelopeDataRequest & {
  id: string | null
}

// Pre Review Envelope Types
export type PreReviewEnvelopeDataRequest = {
  recaptchaToken: string | null
}

export type PreReviewEnvelopeDataRequestWithId =
  PreReviewEnvelopeDataRequest & {
    id: string
  }

export type PreReviewEnvelopeDataResponse = {
  status: string
  envelopeId: string | null
  signerId: string | null
}

// Review Envelope Types
export type ReviewEnvelopeDataRequest = {
  recaptchaToken: string | null
}

export type ReviewEnvelopeDataRequestWithId = ReviewEnvelopeDataRequest & {
  id: string
}

export type ReviewEnvelopeSignerSignMark = {
  id: string
  documentId: string
  type: 'Rubric' | 'Signature'
  page: number
  x: number
  y: number
  rotation: number
}

export type ReviewEnvelopeDataResponse = {
  id: string
  createdAtUtc: string
  createdById: string
  createdByName: string
  createdByAvatar: string
  updatedAtUtc: string | null
  lastUpdateById: string | null
  updatedByName: string | null
  updatedByAvatar: string | null
  userAccountOwnerId: string
  userAccountOwnerName: string
  userAccountOwnerAvatar: string
  language: Lang
  name: string
  description: string
  signInOrder: boolean
  expiresAtUtc: string | null
  isSandbox: boolean
  hasActionsBeingProcessed: boolean
  documents: DocumentType[]
  signers: {
    id: string
    isCurrentSigner: boolean
    index: number
    status: string
    hadApproved: boolean
    approvedAtUtc: string
    hadRejected: boolean
    rejectedAtUtc: string
    name: string
    email: string
    role: string
    title: string
    shouldEnforceEmailValidation: boolean
    shouldEnforceSmsValidation: boolean
    shouldEnforcePasscodeValidation: boolean
    passcodeHint?: string | null
    phoneIdd?: number
    phoneNumber?: string
  }[]
  signMarks: ReviewEnvelopeSignerSignMark[]
  actions: {
    id: string
    createdAtUtc: string
    type: string
    ip: string
    userAgent: string
    location: string
  }[]
}

// Request Email MFA Types
export type RequestEmailMFA = {
  frontendVersion?: string
  recaptchaToken?: string
}

export type RequestEmailMFAWithId = RequestEmailMFA & {
  id: string
}

// Request SMS MFA Types
export type RequestSmsMFA = {
  frontendVersion?: string
  recaptchaToken?: string
}

export type RequestSmsMFAWithId = RequestSmsMFA & {
  id: string
}

// Approve Envelope Types
export type ApproveEnvelopeRequest = {
  frontendVersion: string
  emailCode?: string
  smsCode?: string
  passcode?: string
  signatureMarkId?: string | null
  signatureMarkBase64?: string | null
  rubricMarkId?: string | null
  rubricMarkBase64?: string | null
  recaptchaToken: string
}

export type ApproveEnvelopeRequestWithId = ApproveEnvelopeRequest & {
  id: string
}

// Reject Envelope Types
export type RejectEnvelopeRequest = {
  reason?: string
  frontendVersion: string
  recaptchaToken: string
}

export type RejectEnvelopeRequestWithId = RejectEnvelopeRequest & {
  id: string
}

// Envelope Restore Types
export type EnvelopeRestoreRequest = {
  vaultId: string
}

export type EnvelopeRestoreRequestWithId = EnvelopeRestoreRequest & {
  id: string
}

// Envelope Owner Types
export type OwnersResponse = {
  items: OwnerType[]
}

export type EnvelopeSignerItemType = {
  id: string
  name: string | null
  email: string | null
  role: keyof typeof SignerRoleEnum | null
  title: string | null
  status: keyof typeof SignerEnvelopeStatus
  shouldEnforceEmailValidation: boolean
  shouldEnforcePasscodeValidation: boolean
  shouldEnforceSmsValidation: boolean
  shouldEnforceWhatsAppValidation: boolean
}

// Envelopes List Types
export type EnvelopeItemType = {
  id: string
  createdAtUtc: string
  createdById: string
  createdByName: string
  createdByAvatar?: string
  updatedAtUtc?: string
  lastUpdateById?: string
  updatedByName?: string
  updatedByAvatar?: string
  name: string
  status: keyof typeof EnvelopeStatus
  vaultId: string
  vaultName: string
  signers: EnvelopeSignerItemType[]
}

export type EnvelopesListResponse = {
  items: EnvelopeItemType[]
  pagination: Pagination
}

export type DocumentType = {
  id: string
  fileName: string
  index: number
  name: string
  description: string
  pageSizes: PageSizeEnvelopeType[]
}

export type EnvelopeUnsubscribeRequest = {
  frontendVersion?: string
  reason?: string
  recaptchaToken: string
}

export type EnvelopeUnsubscribeRequestWithId = EnvelopeUnsubscribeRequest & {
  id: string
}

export type EnvelopePublishedType = EnvelopeType & {
  publishedAtUtc: string | null
  publishedById: string | null
  publishedByName: string | null
  publishedByAvatar: string | null
}

export type EnvelopeCancelledType = EnvelopeType & {
  cancelledAtUtc: string | null
  cancelledById: string | null
  cancelledByName: string | null
  cancelledByAvatar: string | null
}

export type EnvelopeExpiredType = EnvelopeType & {
  expiredAtUtc: string | null
}

export type EnvelopeSignedType = EnvelopeType & {
  signedAtUtc: string | null
}

export type EnvelopePublishScheduledType = EnvelopeType & {
  scheduledToBePublishedAtUtc: string | null
}

export type EnvelopeRejectedType = EnvelopeType & {
  rejectedAtUtc: string | null
  rejectedBySignerName: string | null
  rejectedBySignerEmail: string | null
}

export type EnvelopeCancelledBySignerMfaError = EnvelopeType & {
  cancelledAtUtc: string | null
  cancelledBySignerName: string | null
  cancelledBySignerEmail: string | null
}

export type EnvelopeExpireSoon = EnvelopeType & {
  expiresAtUtc: string | null
}

export type EnvelopesListPublishedResponse = {
  items: EnvelopePublishedType[]
  pagination: Pagination
}

export type EnvelopesListCancelledResponse = {
  items: EnvelopeCancelledType[]
  pagination: Pagination
}

export type EnvelopesListExpiredResponse = {
  items: EnvelopeExpiredType[]
  pagination: Pagination
}

export type EnvelopesListSignedResponse = {
  items: EnvelopeSignedType[]
  pagination: Pagination
}

export type EnvelopesListPublishScheduledResponse = {
  items: EnvelopePublishScheduledType[]
  pagination: Pagination
}

export type EnvelopesListRejectedResponse = {
  items: EnvelopeRejectedType[]
  pagination: Pagination
}

export type EnvelopesListCancelledBySignerMfaErrorResponse = {
  items: EnvelopeCancelledBySignerMfaError[]
  pagination: Pagination
}

export type EnvelopesListExpireSoonResponse = {
  items: EnvelopeExpireSoon[]
  pagination: Pagination
}

export type EnvelopePendingType = {
  id: string
  createdAtUtc: string
  ownerId: string
  ownerName: string
  ownerAvatar: string | null
  pendingSinceUtc: string | null
  signerId: string
}

export type EnvelopesListPendingResponse = {
  items: EnvelopePendingType[]
  pagination: Pagination
}

export type EnvelopeApprovedByMeType = {
  id: string
  createdAtUtc: string
  ownerId: string
  ownerName: string
  ownerAvatar: string | null
  approvedAtUtc: string | null
  name: string
}

export type EnvelopesListApprovedByMeResponse = {
  items: EnvelopeApprovedByMeType[]
  pagination: Pagination
}

export type EnvelopeRejectedByMeType = {
  id: string
  createdAtUtc: string
  ownerId: string
  ownerName: string
  ownerAvatar: string | null
  rejectedAtUtc: string | null
  name: string
}

export type EnvelopesListRejectedByMeResponse = {
  items: EnvelopeRejectedByMeType[]
  pagination: Pagination
}

export type EnvelopeSignatureLinkRequest = {
  envelopeId: string
  signerId: string
}

export type EnvelopeSignatureLinkResponse = {
  id: string
}

export type SignerEnvelope = {
  id?: string
  index: number
  status: keyof typeof SignerEnvelopeStatus
  name: string
  email: string
  role: SignerRoleEnum
  title: string
  shouldEnforceEmailValidation?: boolean
  shouldEnforcePasscodeValidation?: boolean
  passcode?: string | null
  passcodeHint?: string | null
  shouldEnforceSmsValidation?: boolean
  phoneIdd?: any
  phoneNumber?: any
  signMarks: SignMarkEnvelopeType[]
}
